.doctorlist-container {
  padding: 0 5%;
  margin-left: 17%;
  padding-top: 5%;
}

.slick-prev::before,
.slick-next::before {
  color: #7aaf07;
  font-size: 60px;
}

.slick-prev {
  left: -4%;
  z-index: 1;
}

.slick-next {
  right: -1%;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 10%;
}

.social-icons a {
  margin: 0 2%;
  color: grey;
  font-size: 20px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  transform: scale(1.4);
  transition: transform 0.3s ease-in-out;
}

.rounded-btn {
  border-radius: 30px;
  width: 80%;
  background-color: #7aaf07;
  color: white;
  border: none;
  padding: 2%;
  transition: transform 0.2s, box-shadow 0.2s;
  outline: none;
  font-weight: 600;
}

.add-doctor-btn {
  border-radius: 30px;
  width: 30%;
  background-color: #7aaf07;
  color: white;
  border: none;
  padding: 1%;
  transition: transform 0.2s, box-shadow 0.2s;
  outline: none;
  font-weight: 600;
}

.rounded-btn:hover,
.add-doctor-btn:hover {
  border: solid 3px #7aaf07;
  background-color: white;
  color: #7aaf07;
}

.btn-link {
  color: #7aaf07;
  text-decoration: none;
  margin-left: 10%;
  font-weight: 600;
}

.btn-link:hover {
  text-decoration: none;
  color: #c2bfbf;
  outline: none;
}

.rounded-btn:active {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transform: scale(0.95);
}

.rounded-btn:focus {
  outline: none;
}

.doctor-name {
  font-weight: 600;
  border-bottom: 3px dotted #c2bfbf;
  padding-bottom: 0.5em;
  display: inline-block;
  width: auto;
  line-height: 1.2;
  text-transform: uppercase;
}

.form-control {
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 10px 15px;
}

.form-label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.doctorform-container {
  margin-left: 30%;
  margin-top: 5%;
  max-width: 600px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
